import React, { Component } from "react";
import ProjectInfoBox from 'src/components/ProjectInfoBox';

import { Link } from "gatsby"
import styled from 'styled-components';

import MEDIA_QUERIES from 'src/consts/media-queries';
import VARIABLES from 'src/consts/variables';

import { FaEdit, FaRegCommentDots, FaClipboardList } from 'react-icons/fa';
import CaretRightActive from '../images/caret-right-active.svg';

import BaseButton from "./BaseButton";

const TableOfContentsWrapper = styled.aside`
  ${MEDIA_QUERIES.LAPTOP_MIN} {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`

const TableOfContentsContainer = styled.div`

  font-size: 12px;
  color: #737086;
  overflow: auto;

  .active {
    color: #0F4FE3;
    background: url(${CaretRightActive}) no-repeat;
    background-position: 0px 4px;
  }

  .menu-caret {
    width: ${VARIABLES.WIDTH_TABLE_OF_CONTENTS_DESKTOP}px;

    ${MEDIA_QUERIES.LAPTOP_MIN} {
      width: ${VARIABLES.WIDTH_TABLE_OF_CONTENTS_LAPTOP}px;
    }

    ${MEDIA_QUERIES.TABLET} {
      max-width: 80vw;
    }

    &:hover {
      background: url(${CaretRightActive}) no-repeat;
      background-position: 0px 4px;
    }
  }

  ${MEDIA_QUERIES.LAPTOP_MIN} {
    position: fixed;
    top: ${VARIABLES.HEIGHT_HEADER_DESKTOP}px;
    padding: 60px 0 100px 0;
    width: ${VARIABLES.WIDTH_TABLE_OF_CONTENTS_LAPTOP}px;
    min-height: calc(100vh - ${VARIABLES.HEIGHT_HEADER_DESKTOP}px - 420px);
    max-height: calc(100vh - ${VARIABLES.HEIGHT_HEADER_DESKTOP}px - 50px);
    &.overlapping {
      position: absolute;
      bottom: 0;
      top: auto;
    }
  }

  ${MEDIA_QUERIES.DESKTOP_MIN} {
    width: ${VARIABLES.WIDTH_TABLE_OF_CONTENTS_DESKTOP}px;
    margin-left: 82px;
  }

  ${MEDIA_QUERIES.HD_ONLY} {
    margin-left: 65px;
  }

  ${MEDIA_QUERIES.TABLET} {
    display: none;
  }

  .container-label {
    color: #111a44;
    opacity: 0.5;
    font-size: 12px;
    line-height: 15px;
    padding-left: 9px;
    margin-bottom: 13px;
  }

  .link-label {
    color: #111a44;
    margin-bottom: 7px;
    margin-left: -2px;
    position: relative;
  }

  .text-middle-align {
    display: inline-block;
    vertical-align: middle;
  }

  .page-title {
    color: #111a44;
    font-weight: 600;
    line-height: 22px;
    font-size: 14px !important;
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-bottom: 1px solid #c5c5c5;
    margin-bottom: 6px;

    ${MEDIA_QUERIES.TABLET} {
      display: none;
    }
  }

  .tooltip {
    position: absolute;
    left: 60px;
    top: -30px; 
    font-style: normal;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 1.42857143;
    text-align: left;
    text-align: start;
    text-shadow: none;
    text-transform: none;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    font-size: 12px;
    margin-top: 5px;
    z-index: 2;
  }

  .tooltip .tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-right-color: transparent;
    border-style: solid;
    top: auto;
    bottom: -5px;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000;
  }

  .tooltip .tooltip-inner {
    max-width: 200px;
    padding: 3px 8px;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: 4px;
  }

  .tooltip.on {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s linear;
  }

  .tooltip.off {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s linear;
  }

  ul {
    font-size: 12px;
    color: #111a44;
    > li {
      padding-top: 2px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:last-of-type{
        margin-bottom: 6px;
      }
      > ul{
        padding-left: 10px;
      }
    }
  }

  a {
    display: block;
    padding: 2px 11px;
    border-radius: 3px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    &:hover{
      color: #0F4FE3;
    }
  }
`;

class TableOfContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollingState: "",
      hasCopied: 'off',
    };
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount(){
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleScroll);
    setTimeout(()=>{this.handleScroll()}, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleScroll);
    this.setState({hasCopied: 'off'});
  }

  /**
   * Handling how the menu will stay in the screen when the menu having different height on desktop
   */
  handleScroll() {

    let headerHeight = (document.querySelector('.Header') || {}).offsetHeight || 0;
    let currentHeight = (this.wrapperElem || {}).offsetHeight || 0;

    //Determine if the top edge of the footer is overlapping the bottom edge of menu.
    let overlapping = document.querySelector('.Footer').getBoundingClientRect().top - headerHeight - currentHeight < 0;

    this.setState({overlapping});
  }

  copyToClipboard(hrefSrc, canonicalVersion) {
    if (typeof navigator.clipboard !== 'undefined') {
      navigator.clipboard.writeText(hrefSrc.replace('latest', canonicalVersion));
    } else {
      let textArea = document.createElement("textarea");
      textArea.value = hrefSrc.replace('latest', canonicalVersion);
      textArea.style.position="fixed";  //avoid scrolling to bottom
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      document.execCommand('copy');
    }
    this.setState({hasCopied: 'on'});
    setTimeout(() => {
      this.setState({hasCopied: 'off'});
    }, 1500);
  }

  getGitBranch = (currentVersion = '') => {
    const regex = /(?<mayor>[0-9]+)\.(?<minor>[0-9]+)\.?(?<patch>[0-9]*)/;

    if (currentVersion == null)
      currentVersion = '';

    const { groups } = currentVersion.match(regex) || {};
    if (!groups) {
      return 'develop';
    }
    const { mayor, minor } = groups;
    return `release/${mayor}.${minor}.x`;
  }

  render() {
    let {
      htmlContent, contentTitle, slug, isTagged, currentVersion, orgName, repoName, currentReducedVersion,
      canonicalVersion, hrefSrc,
      frontMatter: { source_path }
    } = this.props;
    // Only show the edit or request changes link if not null under two cases:
    // 1. If the repo is untagged
    // 2. If the repo current version is "latest"
    const shouldDisplay = ((isTagged && currentReducedVersion === "latest") || !isTagged) && source_path;
    const shouldDisplayPermalink = currentReducedVersion === 'latest' && isTagged;
    let instructions = `%3C%21--1.%20Please%20change%20the%20issue%20title%20to%20describe%20your%20documentation%20change%20request.%202.%20Acurrately%20describe%20your%20issue%20in%20the%20issue.%203.%20DO%20NOT%20remove%20this%20message%20or%20the%20file%20link%20below--%3E`
    let issueBody = (source_path) ? `${instructions}%0AFile:%20%5B${source_path}%5D(https://github.com/${orgName}/${repoName}/blob/develop/${source_path})&title=%5Bdocs%5D%20Suggestion / Change Request` : `${instructions}%20%20Code:%20%5BSource%5D(https://github.com/${orgName}/${repoName}/blob/develop/)&title=%5Bdocs%5D%20Suggestion / Change Request`;

    const gitBranch = this.getGitBranch(currentVersion);

    return (
      <TableOfContentsWrapper>
        <TableOfContentsContainer ref={e => this.wrapperElem = e}
                              className={"TableOfContents" +
                                          (this.state.overlapping ? " overlapping" : '')}>
          {
            (shouldDisplay) &&
            <BaseButton url={`https://github.com/${orgName}/${repoName}/edit/${gitBranch}/${source_path}`} type="link">
              <FaEdit className="text-middle-align" />&nbsp;
              <span className="text-middle-align">
                Edit
                </span>
            </BaseButton>
          }
          <BaseButton url={`https://github.com/${orgName}/${repoName}/issues/new?body=${issueBody}`} type="link">
            <FaRegCommentDots className="text-middle-align" />&nbsp;
            <span className="text-middle-align">
              Request Changes
            </span>
          </BaseButton>
          {
            (shouldDisplayPermalink) &&
            <div className="link-label">
              <div className={`tooltip ${this.state.hasCopied}`}>
                <div className="tooltip-arrow"></div>
                <div className="tooltip-inner">Copied!</div>
              </div>
              <a href="#" // eslint-disable-line jsx-a11y/anchor-is-valid
                onClick={() => this.copyToClipboard(hrefSrc, canonicalVersion)}>
                <FaClipboardList className="text-middle-align" /> <span className="text-middle-align">
                  Copy Permalink ({canonicalVersion})
                </span>
              </a>
            </div>
          }

          <ProjectInfoBox
            orgName={orgName}
            repoName={repoName}
            isTagged={isTagged}
            currentVersion={currentVersion}
            />

          <div className="container-label">
            On this page:
          </div>

          { contentTitle &&
            <div className="page-title">
              <Link id="ToCHeader" to={slug}>{contentTitle}</Link>
            </div>
          }

          {/* For ContentPage from markdown, htmlContent will be passed in props for rendering the markdown content. */}
          {/* Otherwise, a customized componentContent for RpcPage will be passed in props for rendering . */}
          { htmlContent
            ? <div dangerouslySetInnerHTML={{ __html: htmlContent }}/>
            : this.props.componentContent
          }
        </TableOfContentsContainer>
      </TableOfContentsWrapper>
    )
  }

}

export default TableOfContents;
