import React from "react";
import Menu from "./components/Menu";

import { Location } from '@reach/router';

/**
 * summaryAdapter
 * Pre-process the summary JSON in order to add '/index' to the tree in case necessary
 */

const summaryAdapter = (summary) => {
  for (let i = 0; i < summary.length; i++) {
    if (summary[i].link) {
      let pieces = summary[i].link.split("/");
      let includesIndexTrail = (pieces[pieces.length - 1] === 'index')
      if (!summary[i].link.includes('.md') && 
          (!summary[i].link.includes('index') || !includesIndexTrail) &&
          !summary[i].link.startsWith('http')
        ) {
        summary[i].link = summary[i].link + "/index"
      }
    }
    if (summary[i].sub && summary[i].sub.length > 0) {
      summaryAdapter(summary[i].sub);
    } 
  }

  return summary;
}

/**
 * Static query to retrieve the contents of SUMMARY.json
 * It will be pre-processed into a nice array of objects
 * We will recursively traverse it to populate the sidebar
 */

const Sidebar = ({ repoName, summary, nodes, currentVersion, isTagged, 
  isCollection, pathData, pathKey, importedSummary, latestDDVersion,
  currentDDVersion, tagsList, primaryNavData, pathPrefix, canonicalVersion, 
  minimap, showMenuLinks }) => (
  <Location>
    {
      ({ location }) => {
        return (
          (summary !== {"array": []}) ?
          <div>
             <Menu sub={summaryAdapter(summary.array)} 
              nodes={nodes} location={location} 
              currentVersion={currentVersion} pathKey={pathKey}
              isCollection={isCollection} pathData={pathData} 
              isTagged={isTagged} importedSummary={importedSummary}
              latestDDVersion={latestDDVersion} currentDDVersion={currentDDVersion} 
              tagsList={tagsList} primaryNavData={primaryNavData}
              pathPrefix={pathPrefix} canonicalVersion={canonicalVersion} minimap={minimap}
              showMenuLinks={showMenuLinks}
              {...{repoName}} />
          </div>
          : null
        );
      }
    }
  </Location>
)


export default Sidebar
