import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const BaseButtonWrapper = styled.div`
  font-weight: 600;
  font-size: 13px;
  color: #0F4FE3;
  margin-left: -2px;
  
  &.outline, &.full {
    border: 1px solid;
    border-radius: 5px;
    margin-left: 6px;
    margin-right: 6px;
    &:hover {
      border-color: rgb(0, 25, 175);
    }
    a {
      line-height: 2.3;
      padding-bottom: 5px;
      &:hover {
        color: rgb(0, 25, 175);
      }
    }
  }
  
  &.full {
    background-color: #0F4FE3;
    &:hover {
      background-color: rgb(0, 25, 175);
    }
    a {
      color: white;
      &:hover {
        color: white;
      }
    }
  }
  
`;

/**
 * BaseButton Component
 * @param url
 * @param type
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
function BaseButton({url, type, children}) {
  return (
    <BaseButtonWrapper className={type}>
      <a href={url} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
    </BaseButtonWrapper>
  );
}

BaseButton.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['outline', 'full', 'link']),
};

BaseButton.defaultProps = {
  type: 'link',
};

export default BaseButton;
