import React from "react";

import { Link, useStaticQuery, graphql } from "gatsby"
import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';

const BreadcrumbLabel = styled.span`
  text-transform: capitalize;
`

const CrumbWrapper = styled.div`
  display: inline;
  font-size: 15px;

  ${MEDIA_QUERIES.TABLET} {
    font-size: 14px;
  }
`

const shouldHideIndexBreadcrumbs = (
  currentPath, mockedPath
) => {
  let standardIndexCheck = (currentPath === mockedPath);

  return standardIndexCheck;
}

const Breadcrumbs = props => {
  const { paths, sitedata } = useStaticQuery(
    graphql`{
        paths: allSitePage {
          edges {
            node {
              id
              path
            }
          }
        },
        sitedata: site {
          pathPrefix
        }
      }
    `
  );

  const { 
    location, slug, isTagged, currentVersion, pathKey, pathData, repoName, 
    canonicalVersion
  } = props;
  let rawPath = location.pathname;
  const currentPath = (rawPath.endsWith('/')) ? rawPath : rawPath+"/";
  const crumbs = currentPath.split('/').filter(crumb => crumb !== currentVersion && crumb !== canonicalVersion);
  const pathRepo = paths.edges.map(node => node.node.path);
  const pathPrefix = (sitedata.pathPrefix !== '/undefined') ? sitedata.pathPrefix : null;
  let mockedPath = (isTagged) ? pathKey+"/"+currentVersion : pathKey;

  if (mockedPath[mockedPath.length - 1] !== '/')
    mockedPath += "/";

  const sanitize = string => {
    let newString = "";

    if (string.length > 0) {
      const sanitizeListSubjects = /-|_/g;
      const sanitizeDelimiter = " ";
      newString = string.replace(sanitizeListSubjects, sanitizeDelimiter);
    }

    return newString.replace(/eosio/g, "EOSIO");
  }

  /**
   * Two cases:
   * 1. If there's no pathprefix, then the home slug starts from index 1.
   * 2. If there IS a pathprefix, then the home slug starts from index 2, 
   *    because the allSitePage query paths in each node ignore the pathprefix.
   *    That means we treat the index page with pathprefix as actually being
   *    the slug at index 1 instead of index 0 
   */

  return (
    (
      shouldHideIndexBreadcrumbs(currentPath, mockedPath)
      // || ((pathPrefix !== '/' || pathPrefix !== '') && !isTagged)
      || (isTagged && crumbs.length === 4 && currentPath.includes(currentVersion) && currentPath.includes('/index'))
    ) 
    ? null 
    : <div style={{ marginBottom: '1em' }}>
      {
        crumbs.map((crumb, i) => {
          crumb = sanitize(crumb);

          if (
            (i === 0) 
          )
            crumb = 'Home ';         
          else if (
            (
              i >= 1 || 
              (i === 1 && (pathPrefix !== "" || pathPrefix !== "/"))
            ) && 
            (i <= crumbs.length - 3)
          )
            crumb = " / " + crumb; 
          else if (
            i === crumbs.length - 2
          )
            crumb = (crumb === 'index') ? " " : " :: " + crumb;

          /**
           * Three cases:
           * 1. Current page: entire slug
           * 2. Site has no pathprefix: Start constructing slug from index 1
           * 3. Site has pathprefix: Start constructing from index 2, since 
           *    internal Gatsby Link already appends pathprefix to the URL
           */
          let linkTarget = (i === crumbs.length - 2) 
            ? slug
            : (!pathPrefix) ? 
              crumbs.slice(0, i + 1).join('/')
              : crumbs.slice(2, i + 1).join('/');

          
          if (!linkTarget.includes(currentVersion)) {
            linkTarget = linkTarget.replace(`${pathKey}/`, mockedPath);
          }

          if (pathData.filename === 'index.md' && i === crumbs.length - 2) {
            linkTarget += '/index';
          }

          if (crumb === ' / manuals') {
            linkTarget = "welcome/latest/manuals/index";
          }

          if (crumb === ` / ${sanitize(repoName)}`) {
            crumb += ` (${currentVersion === 'latest' ? canonicalVersion : currentVersion})`;
            linkTarget += `/${currentVersion}/index`;
          }
          
          return (
            <CrumbWrapper key={i}>
              {
                (
                  pathRepo.findIndex(el => (
                    el === linkTarget || 
                    el === "/"+linkTarget ||
                    el === linkTarget+"/index" ||
                    el === "/"+linkTarget+"/index"
                  )) >= 0 
                  || (
                      (pathPrefix !== '/' || pathPrefix !== '') && 
                      pathRepo.findIndex(el => (
                        pathPrefix.substr(1)+el === linkTarget || 
                        pathPrefix.substr(1)+el === linkTarget+"/" ||
                        pathPrefix.substr(1)+el === linkTarget+"/index" ||
                        pathPrefix.substr(1)+el === "/"+linkTarget+"/index"
                      )) >= 0
                    )
                  || crumb === 'Home '
                ) 
                ? (<Link
                    to={linkTarget[0] !== '/' ? `/${linkTarget}` : linkTarget}
                    >
                    <BreadcrumbLabel
                      className={
                        (i === crumbs.length - 2 || (i === crumbs.length - 3 && crumbs[i + 1] === "index")) 
                        ? 'embolden' : ''
                      }
                      >
                      {
                        crumb
                      }
                    </BreadcrumbLabel>
                  </Link>)
                : <BreadcrumbLabel>
                  {
                    crumb
                  }
                </BreadcrumbLabel>
              }
            </CrumbWrapper>
          ) 
        })
      }
      </div>
  )
}

export default Breadcrumbs;
