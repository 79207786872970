import "./FullTemplate.scss";

import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';

import React from "react";
import App from 'src/components/App';
import Sidebar from 'src/components/Sidebar';
import { Header } from 'src/components/Header';
import NewFooter from 'src/components/Footer';

const Wrapper = styled.div`
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0

  ${MEDIA_QUERIES.TABLET} {
    padding: 0;
  }

  ${MEDIA_QUERIES.MOBILE} {
    padding: 0;
  }
`;

class FullTemplate extends React.Component {

  render () {
    let {
      children, orgName, repoName, currentVersion, hidesVersion, showSearch, summary,
      pathData, title, isTagged, tagsList, isCollection, slugs, currentDDVersion, contentTitle,
      latestDDVersion, hasPrimaryNavigation, primaryNavData, importedSummary, pathKey, pathPrefix, frontmatter,
      rawTag, canonicalVersion, minimap, showMenuLinks
    } = this.props;
    return (
      <App contentTitle={contentTitle} isTagged={isTagged} frontmatter={frontmatter} rawTag={rawTag}
        canonicalVersion={canonicalVersion}
        {...{orgName, repoName, currentVersion, hidesVersion, showSearch, title }} >
        <div className="FullTemplate">
          {
            summary.array.length > 0 &&
            <nav className="nav-col">
              <Sidebar
                repoName={repoName}
                summary={summary}
                pathData={pathData}
                isTagged={isTagged}
                nodes={slugs}
                isCollection={isCollection}
                currentVersion={currentVersion}
                currentDDVersion={currentDDVersion}
                latestDDVersion={latestDDVersion}
                canonicalVersion={canonicalVersion}
                hidesVersion={hidesVersion}
                importedSummary={importedSummary}
                primaryNavData={primaryNavData}
                pathKey={pathKey}
                tagsList={tagsList}
                pathPrefix={pathPrefix}
                minimap={minimap}
                showMenuLinks={showMenuLinks}
              />
            </nav>
          }
          <main>
            <Wrapper>
              <Container>
                  <Header
                    orgName={orgName}
                    repoName={repoName}
                    currentVersion={currentVersion}
                    showSearch={showSearch}
                    hasPrimaryNavigation={hasPrimaryNavigation}
                    primaryNavData={primaryNavData}
                    isTagged={isTagged}
                    isCollection={isCollection}
                    pathKey={pathKey}
                  />
                {children}
              </Container>
            </Wrapper>
          </main>
        </div>
        <footer>
          <NewFooter />
        </footer>
      </App>
    )
  }
}

export default FullTemplate
