import "./ContentPage.scss";

import React from "react";
import { graphql } from "gatsby"
import { Location } from "@reach/router";

import interpolateString from 'src/actions/string-interpolator';

import FullTemplate from 'src/templates/FullTemplate';
import TableOfContents from './components/TableOfContents';
import Breadcrumbs from './components/Breadcrumbs';

import { PageTitle } from './styled-components/document-page-styled-components';

class ContentPage extends React.Component {
  render(){
    let {
      site: {
        pathPrefix,
        siteMetadata: {
          sitemap,
        },
      },
      allMarkdownRemark: { nodes }, 
      markdownRemark: {
        tableOfContents,
        html,
        frontmatter,
        fields: {
          contentTitle,
          headTagTitle,
          isCollection,
          isTagged,
          organization: orgName,
          originalKey: pathKey,
          pathData,
          repository: repoName,
          slug,
          tagsList,
          latestDDVersion,
          importedSummary,
          hasPrimaryNavigation,
          primaryNavData,
          currentVersion,
          currentDDVersion,
          summaryJson,
          canonicalVersion,
        }
      }
    } = this.props.data;
    let projectInfoVersion = null;
    let actualCanonicalVersion = "none";
    const hidesVersion = null;
    const pathInfo = JSON.parse(pathData);
    const summary = JSON.parse(summaryJson);
    const parsedTagsList = JSON.parse(tagsList);
    const mainNavData = (primaryNavData) ? JSON.parse(primaryNavData) : [{"text":"NONE","path":"EMPTY"}];
    const originalVersionObject = (isTagged) ? 
      parsedTagsList.filter(tag => currentDDVersion === tag.tag_reduced)[0] : null;

    if (originalVersionObject) {
      projectInfoVersion = originalVersionObject.tag;
      actualCanonicalVersion = (canonicalVersion) ? canonicalVersion : originalVersionObject.tag_reduced_friendly;
    }

    if (pathKey === '/') pathKey = "";

    let hrefSrc = (typeof window !== 'undefined') ?
      window.location.href : "";

    let interpolateProps = {
      orgName: orgName,
      repoName: repoName,
      currentVersion: currentVersion,
      rawTag: projectInfoVersion
    }

    return (
      <FullTemplate
        orgName={orgName}
        repoName={repoName}
        contentTitle={contentTitle}
        currentVersion={currentVersion}
        hidesVersion={hidesVersion}
        showSearch={false}
        isTagged={isTagged}
        title={headTagTitle}
        summary={summary}
        pathData={pathInfo}
        slugs={nodes}
        currentDDVersion={currentDDVersion}
        latestDDVersion={latestDDVersion}
        isCollection={isCollection}
        hasPrimaryNavigation={hasPrimaryNavigation}
        primaryNavData={mainNavData}
        rawTag={projectInfoVersion}
        tagsList={tagsList}
        importedSummary={importedSummary}
        pathKey={pathKey}
        pathPrefix={pathPrefix}
        frontmatter={frontmatter}
        canonicalVersion={actualCanonicalVersion}
        minimap={sitemap}
        showMenuLinks={true}
      >
        <article className="ContentPage document-page-container">
          <TableOfContents
            isTagged={isTagged}
            currentVersion={projectInfoVersion}
            currentReducedVersion={currentVersion}
            frontMatter={frontmatter}
            orgName={orgName}
            repoName={repoName}
            htmlContent={tableOfContents}
            contentTitle={contentTitle}
            slug={slug}
            canonicalVersion={actualCanonicalVersion}
            hrefSrc={hrefSrc}
          />
        <Location>
        {
          ({ location }) => (
              <section>
                <Breadcrumbs location={location} 
                  slug={slug}
                  title={contentTitle}
                  pathKey={pathKey}
                  pathData={pathInfo}
                  currentVersion={currentVersion}
                  isTagged={isTagged}
                  repoName={repoName}
                  importedSummary={importedSummary}
                  isCollection={isCollection}
                  canonicalVersion={actualCanonicalVersion}
                  />
                { (frontmatter.content_title) &&
                  <PageTitle title={interpolateString(frontmatter.content_title, interpolateProps)} className="document-page-title">
                    <h1>{interpolateString(frontmatter.content_title, interpolateProps)}</h1>
                  </PageTitle>
                }
                <div className="document-page-content"
                  dangerouslySetInnerHTML={
                    { __html: html }
                  }
                />
              </section>
          )
        }
        </Location>
        </article>
      </FullTemplate>
    )
  }
}

export default ContentPage

export const query = graphql`
    query($slug: String!) {
        site {
          pathPrefix
          siteMetadata {
            sitemap {
              key,
              org,
              repo,
              root,
            }
          }
        }
        allMarkdownRemark {
          nodes {
            fields {
              slug
            }
          }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
          html
          tableOfContents
          frontmatter {
            content_title
            hide_toc
            link_text
            link_title
            page_description
            page_title
            source_path
          }
          fields {
            articleTitle
            contentTitle
            headTagTitle
            internalSummary
            isCollection
            isRoot
            isTagged
            organization
            originalKey
            pathData
            repository
            showSearch
            slug
            tagsList
            latestDDVersion
            importedSummary
            hasPrimaryNavigation
            primaryNavData
            currentVersion
            currentDDVersion
            canonicalVersion
            summaryJson
          }
        }
    }
`
