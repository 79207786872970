import React from 'react';

import styled from 'styled-components';
import { FaExternalLinkAlt } from 'react-icons/fa';

const InfoContainer = styled.div`
  border: 1px solid #c5c5c5;
  padding: 0 6px 6px 6px;
  margin: 6px 6px 15px 6px;

  path {
    fill: unset;
    stroke: #111a44;
  }
`

const Header = styled.div`
  color: #111a44;
  font-weight: 600;
  border-bottom: 1px solid #c5c5c5;
  padding: 8px 0;
  margin-bottom: 5px;
`

const InfoListItem = styled.li`
  color: #111a44;
`

const ExternalLink = ({ href, classname = "", children }) => <a href={href}
  target="_blank" rel="noopener noreferrer" className={classname}>
    {children}
  </a>

const ProjectInfoBox = props => {
  const {
    orgName, repoName, isTagged, currentVersion,
  } = props;

  return (
    <InfoContainer>
      <Header>Project Information</Header>
        <ol style={{padding: '2px 0'}}>
          <InfoListItem>
            <ExternalLink href={`https://github.com/${orgName}/${repoName}`}>
              On Github <FaExternalLinkAlt />
            </ExternalLink>
          </InfoListItem>
          {
            isTagged &&
            <InfoListItem>
              <ExternalLink 
                href={isTagged ?
                  `https://github.com/${orgName}/${repoName}/releases/tag/${currentVersion}`
                : `https://github.com/${orgName}/${repoName}/releases/latest`
                }
                >
                Release Notes <FaExternalLinkAlt className="svg-override"/>
              </ExternalLink>
            </InfoListItem>
          }
          <InfoListItem>
            <ExternalLink href={`https://github.com/${orgName}/${repoName}/blob/master/CONTRIBUTING.md`}>
              Contributing <FaExternalLinkAlt className="svg-override"/>
            </ExternalLink>
          </InfoListItem>
          <InfoListItem>
            <ExternalLink href={`https://github.com/${orgName}/${repoName}/issues`}>
              Issues <FaExternalLinkAlt className="svg-override"/>
            </ExternalLink>
          </InfoListItem>
          <InfoListItem>
            <ExternalLink href={`https://github.com/${orgName}/${repoName}/blob/master/LICENSE`}>
              License <FaExternalLinkAlt className="svg-override" />
            </ExternalLink>
          </InfoListItem>
        </ol>
    </InfoContainer>
  );
};

export default ProjectInfoBox;
